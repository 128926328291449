@import 'config';

.shop-nav {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;

  &__items {
    display: flex;

    &__item {
      margin-right: $base-spacing;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__link {
      position: relative;
      width: 30px;
      height: 30px;
      display: block;
      text-decoration: none;
      transition: background-image .2s ease-in-out;

      &--wishlist {
        background-image: url(../../public/images/icon_wishlist.svg);
        background-repeat: no-repeat;
      }

      &--wishlist-selected {
        background-image: url(../../public/images/icon_wishlist_filled.svg);
        background-repeat: no-repeat;
      }

      &--checkout {
        background-image: url(../../public/images/icon_bag.svg);
        background-repeat: no-repeat;
      }

      &--checkout-selected {
        background-image: url(../../public/images/icon_bag_filled.svg);
        background-repeat: no-repeat;
      }
    }

    &__count {
      display: flex;
      position: absolute;
      right: -2px;
      bottom: 0;
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: $color-gold;
      color: $font-color-light;
      font-size: 9px;
      font-weight: 400;
      border: 1px solid $font-color-light;
    }
  }
}

@media (max-width: $mobile-width) {
  .shop-nav {
    position: absolute;
    top: $mobile-icon-top;
    right: $base-spacing;
  }
}