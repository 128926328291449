@import 'config';

.button {
  display: flex;
  font-size: $font-size;
  font-weight: $font-weight;
  font-family: $font-main;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: $color-light;
  color: $color-dark;
  border-radius: 5px;
  border: 1px solid $color-dark;
  padding: $base-spacing;
  margin: $base-spacing 0;
  text-decoration: none;
  cursor: pointer;

  &--main {
    text-transform: uppercase;
    background-color: $color-dark;
    color: $color-light;
  }

  &:hover {
    border-color: $color-gold;
  }
}