@import 'config';

.contact-number {
  display: flex;
  flex: 1 1;

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;

    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      background-image: url(../../public/images/icon_phone.svg);
      background-repeat: no-repeat;
      margin-right: $base-spacing / 2;
    }
  }
}

@media (max-width: $mobile-width) {
  .contact-number {
    display: none;
  }
}