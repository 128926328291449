@import 'config';

.nav-item {
  position: relative;
  padding: $base_spacing;

  &__link {
    font-family: $font-main;
    font-size: $font-size;
    color: $font-color-dark;
    text-decoration: none;
    padding-bottom: $base-spacing / 2;
    white-space: nowrap;

    &:hover {
      border-bottom: 2px solid $color-gold;
    }
  }

  &:hover {
    .nav-item-children {
      display: flex;
    }
  }

  &__select {
    display: none;
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    outline: none;
    background: url(../../public/images/icon_arrow.svg) no-repeat center center;
    background-size: contain;
    cursor: pointer;
  }
}

.nav-item-children {
  display: none;
  position: absolute;
  justify-content: left;
  flex-direction: column;
  top: 30px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 100;
  padding: 20px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);
  border-top: 2px $color-gold solid;
  background: #fff;

  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -7px;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $color-gold;
  }
}

@media (max-width: $mobile-width) {
  .nav-item {
    display: flex;
    justify-content: space-between;
    position: unset;
    border-bottom: 1px solid $color-dark;
    padding: $base-spacing;

    &__link {
      padding: $base-spacing;

      &:hover {
        border-bottom: none;
      }
    }

    &:hover {
      .nav-item-children {
        display: none;
      }
    }

    &__select {
      display: inline-block;
    }
  }

  .nav-item-children {
    position: absolute;
    width: auto;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    box-shadow: none;
    transform: unset;
    -ms-transform: none;
    border-top: none;
    padding: 0;

    &:before {
      display: none;
    }

    &--open {
      display: flex !important;
    }
  }
}