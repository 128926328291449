@import 'config';

.main-nav {
  flex-basis: 100%;
  margin-top: $base-spacing;

  &__items {
    width: fit-content;
    display: flex;
    margin: 0 auto;

    &__item {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 40%;
        top: 33%;
        right: 0;
        background-color: $color-dark;
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }

  &__burger {
    display: none;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    background-image: url(../../public/images/icon_menu.svg);
    background-repeat: no-repeat;
  }

  &__burger-close {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    outline: none;
    background: url(../../public/images/icon_cross.svg) no-repeat $base-spacing / 2 center;
    background-size: contain;
    cursor: pointer;
  }

  &__header {
    display: none;
    padding: $base-spacing * 2;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-dark;
    font-weight: 400;
  }

  &__title {
    font-size: $font-size-medium;
  }
}

@media (max-width: $mobile-width) {
  .main-nav {
    position: absolute;
    top: $mobile-icon-top;
    left: $base-spacing;
    margin-top: 0;

    &--open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100vw;
      background: $color-background;
      z-index: 10000;
    }

    &__burger {
      display: block;
    }

    &__items {
      position: relative;
      display: none;
      width: auto;

      &__item--selected {
        border-bottom: none;
      }
    }

    &--open &__burger {
      display: none;
    }

    &--open &__items {
      display: block;
    }

    &--open &__header {
      display: flex;
    }
  }
}