@import 'config';

.social-media {
  display: flex;

  &__platform {
    margin-right: 1px;

    &:last-of-type {
      margin-right: 0;
    }

    &__link {
      display: block;
      text-decoration: none;
      height: 36px;
    }

    &__image {
      height: 100%;
      filter: brightness(100);
    }
  }
}