@import 'reset';
@import 'config';

html, body {
  background-color: $color-background;
  font-family: $font-main;
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  color: $font-color-dark;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: $min-content-width;
}

img {
  overflow: hidden;
}

a {
  font-family: $font-main;
  font-style: normal;
  font-weight: 200;
  font-size: $font-size;
  color: $font-color-dark;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-content-width;
  margin: 0 auto;
  padding: $base-spacing 0 $base-spacing * 2 0;
  flex: 1;
}

.grecaptcha-badge {
  visibility: hidden;
}