@import 'config';

.cookie-preference {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: $base-spacing;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: $color-background;
  border-top: 2px solid $color-gold;

  &__notice {
    padding: $base-spacing;
  }

  &__options {
    display: flex;

    &__option {
      margin-right: $base-spacing;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}