@import 'config';

$policy-width: 300px;

@keyframes slidePolicyInFromRight {
  from {
    right: -$policy-width
  }

  to {
    right: 0
  }
}

.policy {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: $policy-width;
  overflow-y: scroll;
  padding: $base-spacing;
  background: $color-light;
  box-shadow: #000 9px 7px 22px;
  z-index: 100;
  animation-name: slidePolicyInFromRight;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-large;
    font-weight: 400;
    margin-bottom: $base-spacing * 2;
  }

  &__close {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    background: url(../../public/images/icon_cross.svg) no-repeat center center;
    background-size: contain;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: $base-spacing;
    }

    &__header {
      font-weight: 400;
    }
  }
}

@media (max-width: $mobile-width) {
  .policy {
    width: auto;
    left: 0;
    max-width: 100vw;
    animation-name: unset;
  }
}