@import 'config';

.notice {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    padding: $base-spacing * 4;
    background: $color-grey;
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    background: $color-background;
    padding: $base-spacing * 4;
    flex-basis: 60%;
    min-height: 350px;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.10);
    flex-grow: 1;

    &__header {
      font-size: $font-size-x-large;
      font-weight: 400;
      margin-bottom: $base-spacing * 3;
    }

    &__message {
      margin-bottom: $base-spacing * 2;
      text-align: center;
    }
  }

  &__continue {
    margin-top: $base-spacing * 2 !important;
    padding: $base-spacing $base-spacing * 4 !important;
    align-self: center;
  }
}