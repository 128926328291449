@import 'config';

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $base-spacing;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    max-width: $max-content-width;
    padding: 0 $base-spacing;
    box-sizing: border-box;
  }
}

@media (max-width: $mobile-width) {
  .header {
    &__content {
      justify-content: center;
    }
  }
}