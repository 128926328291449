@import 'config';

.site-banner {
  background: $color-dark;
  width: 100%;
  margin-top: $base-spacing;

  &__content {
    padding: $base-spacing * 2 $base-spacing;
    display: flex;
    justify-content: center;
    color: $font-color-light;
    font-size: $font-size-large;
    max-width: $max-content-width;
    margin: 0 auto;
    line-height: $font-size-large + 4px;
  }

  &__link {
    text-decoration: none;
    color: $font-color-light;
    font-size: $font-size-large;
  }
}

@media (max-width: $mobile-width) {
  .site-banner {
    margin-top: $base-spacing + ($base-spacing / 2);
  }
}