@import 'config';

$thickness: 6px;

.spinner-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  justify-self: center;
}

.spinner {
  height: 50px;
  width: 50px;
  border: $thickness solid #fff;
  border-radius: 100%;
  border-top: $thickness solid $color-gold;
  border-right: $thickness solid $color-gold;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}