@import 'config';

.footer {
  border-top: 3px solid $color-gold;
  background: $color-dark;
  color: $font-color-light;
  padding: $base-spacing;
  line-height: $font-size + 6px;

  &__content {
    display: flex;
    max-width: $max-content-width;
    margin: 0 auto;
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: $base-spacing;
  }

  &__link {
    position: relative;
    color: $font-color-light;
    text-decoration: none;
    font-weight: $font-weight;
    font-size: $font-size;
    font-family: $font-main;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid $color-gold;
    }

    &--with-arrow:after {
      display: inline-block;
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      margin-top: 2px;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url(../../public/images/icon_arrow.svg);
      background-size: 18px 18px;
      filter: brightness(100);
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: $base-spacing;

    &__title {
      font-size: $font-size-large;
      font-weight: 400;
      margin-bottom: $base-spacing;
    }
  }

  &__newsletter {
    margin-left: auto;
    width: 27.5%;

    &__field {
      font: $font-main;
      font-size: $font-size;
      font-weight: 200;
      color: $font-color-light;
      background: $color-dark;
      border: none;
      margin-top: $base-spacing;
      padding: 0 0 $base-spacing;
      border-bottom: 1px solid $color-light;

      &:hover {
        border-bottom-color: $color-gold;
      }

      &::placeholder {
        font: $font-main;
        font-size: $font-size;
        font-weight: 200;
        color: $font-color-light;
      }

      &:focus {
        outline: none;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    &__button {
      font: $font-main;
      font-size: $font-size;
      font-weight: 200;
      color: $font-color-light;
      padding: $base-spacing;
      background: $color-dark;
      border: 1px solid $color-light;
      margin-top: $base-spacing * 2;
      text-align: center;
      cursor: pointer;

      &:hover {
        border-color: $color-gold;
      }
    }

    &--error &__message {
      color: $color-error;
    }
  }

  &__recaptcha {
    margin-top: $base-spacing / 2;
    font-size: 8px;
    color: $color-light;

    a {
      font-size: 8px;
      color: $color-light;
    }
  }
}

@media (max-width: $mobile-width) {
  .footer {
    &__content {
      flex-direction: column;
    }

    &__newsletter {
      margin-left: 0;
    }

    &__section {
      width: auto;
    }
  }
}