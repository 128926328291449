
.logo {
  position: relative;
  display: flex;

  &__link {
    display: block;
  }

  &__image {
    width: 157px;
  }

  &__text {
    position: absolute;
    color: transparent;
    z-index: -1;
  }
}