$color-gold: #daa520;
$color-background: #fff;
$color-dark: #113350;
$color-light: #fff;
$color-grey: #fafafa;
$color-sale: #4e0220;
$color-error: rgb(220, 88, 88);

$font-main: Helvetica, sans-serif;
$font-size: 14px;
$font-weight: 200;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-x-large: 22px;
$font-color-dark: $color-dark;
$font-color-light: $color-light;
$max-content-width: 1256px;
$min-content-width: 345px;
$base-spacing: 10px;

$tablet-width: 900px;
$mobile-width: 780px;
$mobile-icon-top: 30px;
$mobile-search-header-height: 50px;